export const interactionWithAPIConstants = {
    createInteractionWithAPI : {
      REQUEST: 'CREATE_INTERACTION_WITH_API_REQUEST',
      SUCCESS: 'CREATE_INTERACTION_WITH_API_SUCCESS',
      FAILURE: 'CREATE_INTERACTION_WITH_API_FAILURE',
    },
    deleteInteractionWithAPI : {
      REQUEST: 'DELETE_INTERACTION_WITH_API_REQUEST',
      SUCCESS: 'DELETE_INTERACTION_WITH_API_SUCCESS',
      FAILURE: 'DELETE_INTERACTION_WITH_API_FAILURE',
    },
    getInteractionsWithAPIPaged : {
      REQUEST: 'GET_INTERACTIONS_WITH_API_PAGED_REQUEST',
      SUCCESS: 'GET_INTERACTIONS_WITH_API_PAGED_SUCCESS',
      FAILURE: 'GET_INTERACTIONS_WITH_API_PAGED_FAILURE',
      CLEAR: 'GET_INTERACTIONS_WITH_API_PAGED_CLEAR'
    },
    getInteractionsWithAPIList : {
      REQUEST: 'GET_INTERACTIONS_WITH_API_LIST_REQUEST',
      SUCCESS: 'GET_INTERACTIONS_WITH_API_LIST_SUCCESS',
      FAILURE: 'GET_INTERACTIONS_WITH_API_LIST_FAILURE',
      CLEAR: 'GET_INTERACTIONS_WITH_API_LIST_CLEAR'
    },
    restoreInteractionWithAPI : {
      REQUEST: 'RESTORE_INTERACTION_WITH_API_REQUEST',
      SUCCESS: 'RESTORE_INTERACTION_WITH_API_SUCCESS',
      FAILURE: 'RESTORE_INTERACTION_WITH_API_FAILURE',
    },
    updateInteractionWithAPI : {
      REQUEST: 'UPDATE_INTERACTION_WITH_API_REQUEST',
      SUCCESS: 'UPDATE_INTERACTION_WITH_API_SUCCESS',
      FAILURE: 'UPDATE_INTERACTION_WITH_API_FAILURE',
    },
    clearInteractionWithAPICRUDvalue : 'CLEAR_INTERACTION_WITH_API_CRUD_VALUE'
  }