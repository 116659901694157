export const messageTemplateConstants = {

	AddOrgDocumentScale: {
		REQUEST : 'ADD_ORG_DOCUMENT_SCALE_REQUEST',
		SUCCESS : 'ADD_ORG_DOCUMENT_SCALE_SUCCESS',
		FAILURE : 'ADD_ORG_DOCUMENT_SCALE_FAILURE',
		CLEAR   : 'ADD_ORG_DOCUMENT_SCALE_CLEAR'
	},
	DownloadDocsScaleExample: {
		REQUEST : 'DOWNLOAD_DOCUMENT_SCALE_EXAMPLE_REQUEST',
		SUCCESS : 'DOWNLOAD_DOCUMENT_SCALE_EXAMPLE_SUCCESS',
		FAILURE : 'DOWNLOAD_DOCUMENT_SCALE_EXAMPLE_FAILURE'
	},
	DownloadDocsScale: {
		REQUEST : 'DOWNLOAD_DOCUMENT_SCALE_REQUEST',
		SUCCESS : 'DOWNLOAD_DOCUMENT_SCALE_SUCCESS',
		FAILURE : 'DOWNLOAD_DOCUMENT_SCALE_FAILURE'
	},
	GetMessageTemplates: {
		REQUEST : 'GET_MESSAGE_TEMPLATES_REQUEST',
		SUCCESS : 'GET_MESSAGE_TEMPLATES_SUCCESS',
		FAILURE : 'GET_MESSAGE_TEMPLATES_FAILURE'
	},
	GetOrgDocumentScale: {
		REQUEST : 'GET_ORG_DOCUMENT_SCALE_REQUEST',
		SUCCESS : 'GET_ORG_DOCUMENT_SCALE_SUCCESS',
		FAILURE : 'GET_ORG_DOCUMENT_SCALE_FAILURE',
		CLEAR   : 'GET_ORG_DOCUMENT_SCALE_CLEAR'
	},
	GetOrgDocumentScales: {
		REQUEST : 'GET_ORG_DOCUMENT_SCALES_REQUEST',
		SUCCESS : 'GET_ORG_DOCUMENT_SCALES_SUCCESS',
		FAILURE : 'GET_ORG_DOCUMENT_SCALES_FAILURE',
		CLEAR   : 'GET_ORG_DOCUMENT_SCALES_CLEAR'
	},
	Create: {
		REQUEST : 'CREATE_MESSAGE_TEMPLATE_REQUEST',
		SUCCESS : 'CREATE_MESSAGE_TEMPLATE_SUCCESS',
		FAILURE : 'CREATE_MESSAGE_TEMPLATE_FAILURE'
	},
	Delete: {
		REQUEST : 'DELETE_MESSAGE_TEMPLATE_REQUEST',
		SUCCESS : 'DELETE_MESSAGE_TEMPLATE_SUCCESS',
		FAILURE : 'DELETE_MESSAGE_TEMPLATE_FAILURE'
	}
}